
.borderLinesForCheckList{
    text-align: center;
    font-weight: bold;
    border-left: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
}
.heightForChecklist{

    text-align: center;
    padding-top: 30px;
    padding-bottom: 30px;
}
.bottomPadding{
    padding-bottom: 20px;
}
.subtitle{
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #FFFFFF !important;
}

.customButtonInverted{
	background-color:#ffffff;
	border-radius: 8px;
	border:2px solid #ffffff;
	display:inline-block;
	color:#1a4c61;
	font-size:17px;
	padding:16px 31px;
	text-decoration:none;
}
.customButtonInverted:hover{
    background-color: #1a4c61;
    color:#ffffff
}
.center{
    display: flex;
    justify-content: center;
}

.darkBlueHeaderText{
    color:#1a4c61;
    font-size: 60px;
    line-height: 60px;
    font-weight: 600;
}
.demoOnTopOfImg{
    z-index: 2;
    position: absolute;
    margin-top: -100px;
    margin-left: 32vw;
}
@media only screen and (max-width: 375px) {
    .demoOnTopOfImg{
        margin-left: 20vw;
        margin-top: -70px;
    }
}

@media only screen and (max-width: 959px) {
    .borderLinesForCheckList{
        border-left: 0px solid #ffffff;
        border-right: 0px solid #ffffff;
        border-top: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
    }
}