.customButton{
	background-color:#1a4c61;
	border-radius: 8px;
	border:3px solid #1a4c61;
	display:inline-block;
	color:#ffffff;
	font-size:17px;
	padding:6px 31px;
	text-decoration:none;
    cursor: pointer;
}
.customButton:hover{
    background-color: #ffffff;
    color:#1a4c61
}
